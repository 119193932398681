import type { Notification } from '@nuxt/ui/dist/runtime/types'

export type HubToastType = 'success' | 'info' | 'danger' | 'warning' | 'custom'

export function useHubToast(toastBody: Partial<Notification> | string, type: HubToastType = 'custom') {
  const toastDesign: Partial<Notification> = {
    icon: '',
    color: 'white',
    ui: {
      position: 'top-0 left-0',
      title: 'text-sm font-medium text-white',
      description: 'mt-1 text-sm leading-4 text-white',
      background: 'bg-grey-blue dark:bg-gray-800',
      ring: '',
      icon: {
        base: 'text-white example-class',
        color: 'text-white'
      }
    },
    closeButton: {
      icon: 'i-mdi-close',
      color: 'white',
      ui: {
        variant: {
          link: 'text-white bg-inherit'
        }
      }
    }
  }

  switch (type) {
    case 'info':
      toastDesign.icon = 'i-mdi-information-outline'
      toastDesign.ui.background = 'bg-blue-3'
      break
    case 'success':
      toastDesign.icon = 'i-mdi-check-circle-outline'
      toastDesign.ui.background = 'bg-dark-green'
      break
    case 'warning':
      toastDesign.icon = 'i-mdi-alert-circle-outline'
      toastDesign.ui.background = 'bg-dark-orange'
      break
    case 'danger':
      toastDesign.icon = 'i-mdi-close-circle-outline'
      toastDesign.ui.background = 'bg-dark-red'
      break
  }

  let hubToastBody: object
  if (typeof toastBody === 'string') {
    hubToastBody = { title: toastBody }
  } else {
    hubToastBody = toastBody
  }

  useToast().add({
    ...toastDesign,
    ...hubToastBody
  })
}
